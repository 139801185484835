<template>
  <div style="margin-top: 5rem;">
    <ComoFuncionaS1 v-if="!getUrlParams"/>
    <Escaner v-if="getUrlParams"/>
  </div>
  <PreguntasFrecuentes></PreguntasFrecuentes>
</template>
<script>
// @ is an alias to /src
import ComoFuncionaS1 from '@/components/ComoFunciona/ComoFuncionaS1.vue'
import Escaner from '@/components/ComoFunciona/Escaner.vue'
import { useMeta } from 'vue-meta'
import PreguntasFrecuentes from '../components/Landings/PreguntasFrecuentes.vue';
export default {
  name: 'ComoFunciona',
  components: {
    ComoFuncionaS1,
    Escaner,
    PreguntasFrecuentes
  },
  methods: {

  },
  setup() {
    useMeta({
      title:'Cómo Funciona el Crédito con Aval Coche',
      meta: [
        {name:'robots', content:'index'},
        {name:'description', content:'Préstamo con aval de coche. Funcionamos sin cambio de titularidad, sin rentings, amortizando y con un proceso 100% online para conseguir el dinero en 48 horas.'},
        {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
      ],
      link:[
        {href: 'https://ibancar.com/como-funciona', rel:'alternate', hreflang:'es-es'},
        {href: 'https://ibancar.mx/como-funciona', rel:'alternate', hreflang:'es-MX'}
      ]
    })
  },
  computed: {
    getUrlParams: function() {
      if(this.$route.name == 'Escaner') {
        return true
      }else {
        return false
      }
    }
  }
}
</script>