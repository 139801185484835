<template>
  <img 
    v-if="isCollapsed"
    :width="width" 
    :height="height" 
    class="icon-fluid"
    src="@/assets/img/arrowDown.svg"
    alt="Arrow down"
    :data-toggle="toggle" />
<img 
  v-else 
  :width="width" 
  :height="height" 
  class="icon-fluid"
  src="@/assets/img/arrowUp.svg"
  alt="Arrow up" 
  :data-toggle="toggle"
  />
</template>

<script>
  export default {
    name: "ArrowToggleButton",
    props: {
      width: String,
      height: String,
      isCollapsed: Boolean,
      toggle: String
    },
    watch: {
      isCollpased: function(newvalue){
        console.log(newvalue)
      }
    }
  }
</script>